import config from "config";
import { TENANT_OPTIONS_DEFAULT } from "fe-shared/src/consts";

export const USER_DATA_STORAGE_KEY = "userData";
export const SESSION_ID_STORAGE_KEY = "sessionId";
export const PREV_LOCATION_KEY = "prevLocation";
export const CANCELED_REQUEST = "isCanceled";
export const REFRESH_TOKEN_URL = `${config.IDENTITY_HOST_URL}api/ExternalAccount/refreshtoken`;
export const ROUTES = {
  root: "/",
  login: "/login",
  loginCallback: "/login/callback",
  transactions: "/transactions",
  participants: "/participants",
  applications: "/applications",
  admin: "/platform-settings",
  requests: "/requests",
  transfers: "/transfers",
  invoices: "/invoices",
  remittances: "/remittances",
  paperForms: "/paper-forms",
  endMarket: "/end-market",
  inventoryAdjustments: "/inventory-adjustments",
};

export const PERMISSIONS = {
  applications: "Portal.EnrolmentRequests",
  participants: "Portal.Participants",
  transactions: "Portal.TransactionManagement",
  admin: "Portal.Admin",
  requests: "Portal.LotrAndGotrTirePickupRequest",
  platformSettings: "Portal.PlatformSettings",
  transfers: "Portal.StorageTransfersMenu",
  inventoryAdjustments: "Portal.InventoryAdjustmentsList",
  invoices: "Portal.Invoice",
  remittances: "Portal.Remittances",
  paperForms: "Portal.PaperForms",
  endMarket: "Portal.EndMarket",
};

export const EMPTY_USER_DATA = {
  token: undefined,
  data: undefined,
  refreshToken: undefined,
};

export const NOTIFICATIONS_NUMBER_LIMIT = 10;

export const ENTITIES_TO_LINKS_MAP: { [key: string]: string } = {
  Company: "participants",
  Participant: "participants",
  Application: "applications",
  Request: "requests",
  Transaction: "transactions",
  Transfer: "transfers",
  Invoice: "invoices",
  Remittance: "remittances",
  EndMarket: "end-market/participants",
};
