import { createContext } from "react";
import { UserInfo } from "fe-shared/src/types/domain";

import { EMPTY_USER_DATA } from "consts";

const user: UserInfo = EMPTY_USER_DATA;

const UserContext = createContext({
  sessionId: null as string | null,
  user,
  setData: (data: UserInfo) => {}, // eslint-disable-line
});

export default UserContext;
