import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Location } from "history";

import {
  Banner,
  LinkButton,
  Loader,
  LogoLayout,
} from "fe-shared/src/components";
import useTenantOptions from "fe-shared/src/hooks/useTenantOptions";

import config from "config";
import { EMPTY_USER_DATA, ROUTES } from "consts";
import UserContext from "contexts/userContext";
import axios from "axios";
import { getUserData, getAccessToken, getTenantOptions } from "./modules/api";

import styles from "./LoginView.module.scss";

interface Props {
  historyStack: Location<any>[];
  isCallback?: boolean;
}

const INACTIVE_ACCOUNT_ERROR_CODE = 702;
const NO_ACCESS_CODE = "NoAccessCode";

const LoginView = ({ historyStack, isCallback = false }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setData } = useContext(UserContext);
  const [_, updateTenantOptions] = useTenantOptions();
  const isCallbackError = useMemo(() => {
    const isLoginPage = history?.location?.pathname === ROUTES.login;
    const isCallbackPagePrev =
      historyStack[1]?.pathname === ROUTES.loginCallback;

    return isLoginPage && isCallbackPagePrev;
  }, [historyStack, history]);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        if (isCallback) {
          const { accessToken: token, refreshToken } = await getAccessToken();

          const userData = await getUserData(token);

          const tenantOptions = await getTenantOptions(token, userData.userId);

          updateTenantOptions(tenantOptions);
          setData({ data: userData, token, refreshToken });
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.data?.errorCode === INACTIVE_ACCOUNT_ERROR_CODE) {
          if (
            error?.response?.data?.additionalInfo?.[0]?.code === NO_ACCESS_CODE
          ) {
            setErrorMessage("noAccess");
          }
          if (error?.response?.data?.additionalInfo === null) {
            setErrorMessage("inactiveAccount");
          }
        } else {
          setErrorMessage("");
        }
        updateTenantOptions({});
        setData(EMPTY_USER_DATA);
        history.push(ROUTES.login);
      }
    })();
  }, [isCallback, setData, history]);

  const loginLink = `${config.IDENTITY_HOST_URL}external/challenge?provider=AzureAD&returnUrl=${window.location.href}/callback`;

  return (
    <LogoLayout>
      <div className={styles.loginViewContent}>
        {isCallback ? (
          <Loader />
        ) : (
          <div className={styles.loginViewBlock}>
            {isCallbackError && !errorMessage && (
              <Banner
                id="session-expired-info-banner"
                title={t("sessionExpired")}
                variant="warning"
              />
            )}
            {errorMessage && (
              <Banner
                id="inactive-account-info-banner"
                title={t(errorMessage)}
                variant="error"
              />
            )}
            <LinkButton
              id="staff-login-button"
              className={styles.loginViewButton}
              to={loginLink}
              isExternal
            >
              {t("loginStaff")}
            </LinkButton>
          </div>
        )}
      </div>
    </LogoLayout>
  );
};

export default LoginView;
