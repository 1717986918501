import { useState, useEffect } from "react";

import { get, set } from "../helpers/storage";
import { TenantOptions } from "../types/domain/User";
import { TENANT_OPTIONS_STORAGE_KEY, TENANT_OPTIONS_DEFAULT } from "../consts";

const useTenantOptions = () => {
  const [tenantOptions, updateTenantOptions] = useState<TenantOptions>(() =>
    get(TENANT_OPTIONS_STORAGE_KEY, TENANT_OPTIONS_DEFAULT, false)
  );

  useEffect(() => {
    set(TENANT_OPTIONS_STORAGE_KEY, tenantOptions, false);
  }, [updateTenantOptions, tenantOptions]);

  return [tenantOptions, updateTenantOptions] as const;
};

export default useTenantOptions;
