import axios from "axios";

import { User } from "fe-shared/src/types/domain";
import { TokenResponse } from "types";

import config from "config";
import { TenantOptions } from "fe-shared/src/types/domain/User";

export const getAccessToken = (): Promise<TokenResponse> => {
  return axios
    .get(`${config.IDENTITY_HOST_URL}api/ExternalAccount/GetAccessToken`, {
      withCredentials: true,
    })
    .then((res) => res.data);
};

export const getUserData = (token: string): Promise<User> => {
  const headers = { Authorization: `Bearer ${token}` };

  return Promise.all([
    axios.get("users/getInfo", { headers }).then((res) => res.data),
    axios
      .get("/users/getPermissions", { headers })
      .then((res) => res.data.permissions),
  ]).then(([userInfo, permissions]) => ({
    ...userInfo,
    permissions,
  }));
};

export const getTenantOptions = (token:string): Promise<TenantOptions> => {
  const headers = { Authorization: `Bearer ${token}` };
  
  return axios
    .get(`getTenantOptions`, { headers } )
    .then((res) => res.data);
};
