import React, {
  InputHTMLAttributes,
  ChangeEvent,
  useMemo,
  ReactNode,
} from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";

import { Tooltip, FormControlMessage } from "..";

import styles from "./Checkbox.module.scss";

export interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "value" | "title"
  > {
  id: string;
  title: React.ReactNode;
  errorText?: string;
  hintText?: string | ReactNode;
  tooltipText?: string;
  icon?: ReactNode;
  isError?: boolean;
  value?: boolean;
  isMultiSelect?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  subTitle?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  messageClassName?: string;
}

const Checkbox = (props: Props) => {
  const {
    id,
    title,
    value = false,
    hintText,
    tooltipText,
    icon,
    errorText,
    isError,
    onChange,
    isMultiSelect = false,
    subTitle,
    labelClassName,
    wrapperClassName,
    messageClassName,
    ...otherProps
  } = props;

  const showErrorMessage = !!errorText && isError;

  const describedBy = useMemo(() => {
    if (showErrorMessage) {
      return `${id}-error-msg`;
    }
    return undefined;
  }, [id, showErrorMessage]);

  return (
    <div className={classnames(styles.checkboxWrapper, wrapperClassName)}>
      {subTitle && <div className={classnames(styles.subTitle)}>
        {subTitle}
      </div>}
      <div className={styles.checkboxBlock}>
        <input
          id={id}
          type="checkbox"
          checked={value}
          aria-invalid={!!isError}
          aria-describedby={describedBy}
          className={styles.checkboxInput}
          onChange={(e) => onChange(e, e.target.checked)}
          {...otherProps}
        />
        <label
          htmlFor={id}
          className={classnames(labelClassName, styles.checkboxLabel, {
            [styles.checkboxError]: isError,
            [styles.checkboxNoWrap]: isMultiSelect,
          })}
        >
          {title}
        </label>
        {!!tooltipText && (
          <Tooltip>
            <ReactMarkdown source={tooltipText} />
          </Tooltip>
        )}
        {icon}
      </div>
      <FormControlMessage
        id={id}
        errorText={errorText}
        hintText={hintText}
        isError={isError}
        className={classnames(styles.checkboxMessage, messageClassName)}
      />
    </div>
  );
};

export default Checkbox;
